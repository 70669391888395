export const NicepayFunctions = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: `
  //[PC 결제창 전용]결제 최종 요청시 실행됩니다. <<'nicepaySubmit()' 이름 수정 불가능>>
function nicepaySubmit(){
\tdocument.payForm.submit();
}

//[PC 결제창 전용]결제창 종료 함수 <<'nicepayClose()' 이름 수정 불가능>>
function nicepayClose(){
\talert("결제가 취소 되었습니다");
}
  `,
    }}
  />
);
