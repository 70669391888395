// window 객체에 property 추가를 위해 전역 타입을 확장해서 타입 정의
declare global {
  interface Window {
    fbq: (param1: string, param2: string, param3?: object) => void;
  }
}

export const track = (name, options = {}, eventId) => {
  if (typeof window !== "undefined" && window.fbq) {
    window.fbq("track", name, options),
      {
        eventId: eventId,
      };
  }
};

export const trackCustom = (name, eventId) => {
  if (typeof window !== "undefined" && window.fbq) {
    window.fbq("trackCustom", name),
      {
        eventId: eventId,
      };
  }
};

export const pageview = (url: string, userId: string, eventId: string) => {
  track("PageView", { url, userId }, eventId);
};

export const viewContent = (
  courseId,
  courseTitle,
  eventId: string,
  isTrial = false
) => {
  track(
    "ViewContent",
    {
      content_category: courseTitle.includes("내일배움단")
        ? "내일배움단"
        : "온라인",
      content_name: isTrial ? `[무료] ${courseTitle}` : courseTitle,
      content_type: "product",
    },
    eventId
  );
};

export const addToCart = (
  courseId,
  courseTitle,
  eventId: string,
  isTrial = false
) => {
  track(
    "AddToCart",
    {
      content_category: courseTitle.includes("내일배움단")
        ? "내일배움단"
        : "온라인",
      content_name: isTrial ? `[무료] ${courseTitle}` : courseTitle,
      content_type: "product",
    },
    eventId
  );
};

export const startTrial = (eventId: string) => {
  track(
    "StartTrial",
    {
      value: "0",
      currency: "KRW",
    },
    eventId
  );
};
