import { instance } from "../axios";
import {
  UserApplicationDto,
  ApplicationRounds,
  FirstApplicationDto,
  SecondApplicationDto,
} from "./application.dto";

export const getUserApplication = async (onlineUserId: string) => {
  try {
    const response = await instance.get<UserApplicationDto>(
      `apply/applyStatus/${onlineUserId}`
    );
    return response.data;
  } catch (e) {
    console.log("유저 지원 정보 조회 에러", e);
    return e.response.data;
  }
};

export const getRecruitingRounds = async () => {
  try {
    const response = await instance.get<ApplicationRounds>(`apply/rounds`);
    return response.data;
  } catch (e) {
    console.log("모집 중인 기수 조회 에러", e);
    return e.response;
  }
};

export const postFirstApplication = async (firstApplication) => {
  try {
    const response = await instance.post<FirstApplicationDto>(`apply/apply1`, {
      ...firstApplication,
    });
    return response.data;
  } catch (e) {
    console.log("1차 지원서 제출 에러", e);
    return e.response.data;
  }
};

export const postSecondApplication = async (secondApplication) => {
  try {
    const response = await instance.post<SecondApplicationDto>(`apply/apply2`, {
      ...secondApplication,
    });
  } catch (e) {
    console.log("2차 지원서 제출 에러", e);
    return e.response.data;
  }
};
