import { useAtom } from "jotai";

import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  ApplicationRoundsDto,
  FirstApplicationDto,
  UserApplicationDto,
  SecondApplicationDto,
} from "./api/application/application.dto";
import {
  getRecruitingRounds,
  getUserApplication,
  postFirstApplication,
  postSecondApplication,
} from "./api/application/application";
import {
  userApplicationAtom,
  firstApplicationAtom,
} from "../states/application";
import { onlineUserIdAtom } from "../states/user";
import { useRouter } from "next/router";

export const useUserApplication = () => {
  const [onlineUserId] = useAtom(onlineUserIdAtom);
  const [, setUserApplication] = useAtom(userApplicationAtom);
  const [firstApplication, setFirstApplication] = useAtom(firstApplicationAtom);
  const router = useRouter();
  const response = useQuery<UserApplicationDto | undefined, Error>(
    ["userApplicationInfo"],
    () => getUserApplication(onlineUserId),
    {
      enabled: onlineUserId.length > 0,
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: (data) => {
        if (data.status === 404 || data.status === 500) {
          alert("지원하는 데에 문제가 생겼습니다. 매니저에게 문의해주세요!");
          window.location.replace("https://hanghae99-v2.spartacodingclub.kr/");
          return;
        }

        setUserApplication(data);
        setFirstApplication({
          ...firstApplication,
          phone: data.user.contactInfo.phone.replace(
            /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
            "$1-$2-$3"
          ),
          onlineUserId: onlineUserId,
          countryCode: data.user.contactInfo.countryCode,
          email: data.user.contactInfo.email,
          experience: "경험 없음",
          myGoal: "개발자로 첫 취업",
          paymentWay: "선불 방식",
        });
      },
    }
  );
  return response;
};

export const useRecruitingRounds = () => {
  const response = useQuery<ApplicationRoundsDto | undefined, Error>(
    ["applicationRounds"],
    () => getRecruitingRounds(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: (data) => {
        if (data.status === 404 || data.status === 500) {
          alert("지원하는 데에 문제가 생겼습니다. 매니저에게 문의해주세요!");
          window.location.replace("https://hanghae99-v2.spartacodingclub.kr");
          return;
        }
      },
    }
  );
  return response;
};

export const useFirstApplication = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  return useMutation(
    (firstApplication: FirstApplicationDto) =>
      postFirstApplication(firstApplication),
    {
      onSuccess: (data) => {
        if (data.status === 404 || data.status === 500) {
          alert("지원하는 데에 문제가 생겼습니다. 매니저에게 문의해주세요!");
          return;
        }
        if (
          data.message === "지원서 제출 필드에 기수 아이디가 유효하지 않습니다."
        ) {
          alert("기수를 선택해주세요!");
          return;
        }
        queryClient.invalidateQueries("userApplicationInfo");
        router.replace("/v2/application/2");
      },
    }
  );
};

export const useSecondApplication = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  return useMutation(
    (secondApplication: SecondApplicationDto) =>
      postSecondApplication(secondApplication),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("userApplicationInfo");
        router.replace("/v2/application/done");
      },
    }
  );
};
