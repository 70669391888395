import { useAtom } from "jotai";
import { getCookie } from "../../businessLogics/util-browser/cookie.util";

import {
  emailAtom,
  passwordAtom,
  readOnlyEmailAtom,
} from "../../states/login/login";
import { onlineInstance } from "../api/axios";

export const useKakaoLoginQuery = () => {
  const onlineURL = process.env.NEXT_PUBLIC_ONLINE_API_URL;
  return async function () {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const settings = {
      redirectUri: `${onlineURL}/v1/oauth/kakao/login`,
      state: JSON.stringify({
        next: "hanghae99-v2",
        hanghae99V2Next: params.next ? encodeURIComponent(params.next) : "",
      }),
    };
    if (typeof window !== "undefined") {
      window.Kakao.Auth.authorize(settings);
    }
  };
};

export const getJSONfromUserInfoCookie = () => {
  let output = { _id: "", name: "", phone: "", email: "", is_kko: false };

  getCookie("userinfo")
    ?.split(/\s*&\s*/)
    .forEach(function (pair) {
      var name = decodeURIComponent(pair.split("=")[0]);
      output[name] = decodeURIComponent(pair.split("=")[1]);
    });
  return output;
};

export const useCheckEmailQuery = () => {
  const [email] = useAtom(readOnlyEmailAtom);
  return async () => {
    return await onlineInstance.get(`/v1/auth/login?email=${email}`);
  };
};

export const useFetchLoginQuery = () => {
  const [email] = useAtom(emailAtom);
  const [password] = useAtom(passwordAtom);
  return async () => {
    return await onlineInstance.post("/v1/auth/login", {
      email,
      password,
    });
  };
};
