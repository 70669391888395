import { useRouter } from "next/router";
import { useEffect } from "react";

import * as gtag from "../../../../lib/gtag";
import { sendFlounder } from "./flounder";

export const useLogPageview = (eventId: string) => {
    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = (url) => {
            gtag.pageview(url);
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.on("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);
};

// 홈페이지 view
export const logViewMain = (eventId: string) => {
    // trackMixpanel("scc_mainPage_view");
    sendFlounder("hh_mainPage_view");
    gtag.viewMain();
};
