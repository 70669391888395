// lib/gtag.js

declare global {
  interface Window {
    gtag: (param1: string, param2: string, param3: object) => void;
  }
}

export const webVital = (id, name, label, value) => {
  if (typeof window !== "undefined" && window.gtag) {
    process.env.IS_DEV && console.log(id, name, label, value);
    window.gtag("event", name, {
      eventCategory:
        label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
      eventAction: name,
      eventValue: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
      eventLabel: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate.
    });
  }
};

export const pageview = (url) => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", process.env.GOOGLE_ANALYTICS_TRACKING_ID, {
      page_path: url,
    });
  }
};

export const viewMain = () => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", "view_main", {});
  }
};

export const marketingGtag = (name, data) => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", name, data);
  }
};

export const gtagReportConversion = (label) => {
  if (typeof window !== "undefined" && window.gtag) {
    window.gtag("event", "conversion", {
      send_to: `${process.env.GOOGLE_ADS_TRACKING_ID}/${label}`,
    });
  }
};
