import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { Provider as StateProvider } from "jotai";
import { ReactQueryDevtools } from "react-query/devtools";
import "../styles/globals.css";
import Script from "next/script";

import Layout from "../src/components/templates/Layout/Layout";
import { GtagInstall } from "../lib/gtag/install";
import Head from "next/head";
import dynamic from "next/dynamic";
import FacebookPixel from "../lib/facebook/pixel";
import React, { useEffect } from "react";
import { initFbq } from "../lib/facebook/init";
import HotjarInstall from "../lib/hotjar/install";
import InitNaverScript from "../lib/naver/init";
import { useHotjar } from "../src/businessLogics/_common/logging/thirdParty/hotjar";
import { useRouter } from "next/router";
import { KakaoPageview } from "../lib/kakaoPixel";
// import Hackle from "../src/components/hackle/Hackle";
import { webVital } from "../lib/gtag";
import { fontFace } from "../styles/fonts";
import { NicepayFunctions } from "../lib/nicepay";
import { NicepayPcScript } from "../lib/nicepay/script";
declare global {
  interface Window {
    ChannelIO: (param1: string, param2: object, param3: Function) => void;
    _nicepayStart: () => void;
  }
}
const queryClient = new QueryClient();

const FlounderScript = dynamic(
  () => import("../lib/logging/flounder/FlounderScript"),
  {
    ssr: false,
  }
);

const ChannelScript = dynamic(() => import("../lib/channelTalk/script"));

export function reportWebVitals({ id, name, label, value }) {
  webVital(id, name, label, value);
}

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  let { url } = router.query;

  useEffect(() => {
    console.log(
      process.env.IS_PROD ? "Prod" : process.env.IS_TEST ? "Stage" : "Dev"
    );
    // if (userId) identifyMixpanel(userId);
    initFbq();
    // initKakao();
  }, []);

  useHotjar();
  // NaverScript();
  return (
    <QueryClientProvider client={queryClient}>
      <Script
        id="kakao-js"
        defer={true}
        src="/js/kakao-dev.js"
        onLoad={() => {
          window.Kakao.init("535068688f1a8bca1c21a9445ede0a89");
        }}
      />
      <StateProvider>
        <Head>
          <NicepayPcScript />
          <NicepayFunctions />
          <link
            rel="preconnect"
            href="https://developers.kakao.com"
            crossOrigin={"true"}
          />
          <link
            rel="preconnect"
            href="https://t1.daumcdn.net"
            crossOrigin={"true"}
          />
          <link
            rel="preconnect"
            href="https://cdn.channel.io"
            crossOrigin={"true"}
          />
          <style>{fontFace}</style>
          <FacebookPixel />
          <HotjarInstall />
        </Head>
        {url && !process.env.IS_DEV && <KakaoPageview url={url.toString()} />}
        {/* <Hackle> */}
        <Hydrate state={pageProps.dehydratedState}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </Hydrate>
        {/* </Hackle> */}
        <ReactQueryDevtools initialIsOpen={false} />
        <FlounderScript />
        <InitNaverScript />
        <GtagInstall />
        <ChannelScript />
      </StateProvider>
    </QueryClientProvider>
  );
}

export default MyApp;
