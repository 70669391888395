import {
  AlarmClock,
  NanumHandWritingDaughter,
  Pretendard,
  SpoqaHanSans,
  AritaBuri,
  GmarketSans,
  NanumDaSiSiJagHae,
} from "./fonts";

export const fontFace = `
    ${Pretendard}
    ${AlarmClock}
    ${SpoqaHanSans}
    ${NanumHandWritingDaughter}
    ${AritaBuri}
    ${GmarketSans}
    ${NanumDaSiSiJagHae}
`;
