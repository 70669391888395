import { secondsToString } from "../../businessLogics/utils/_helper";
import { instance } from "./axios";

interface TeamMembers {
    userId: string;
    name: string;
    isDone: boolean;
    isLate: boolean;
    firstCheckIn: string;
}

interface TeamChecks {
    teamId: string;
    num: number;
    allDone: boolean;
    members: TeamMembers[];
}

interface Ranks {
    weekOrder: number;
    rankedUsers: [
        {
            name: string;
            totalTime: number;
            secondsPerDay: number[];
        }
    ];
}

export interface UserCheckIn {
    userId: string;
    userName: string;
    totalSecondsStudied: number;
    todaySecondsStudied: number;
    totalSecondsStudiedString: string;
    isRunning: true;
    logs: [
        {
            type: string;
            time: string;
        }
    ];
    message?: string;
}

export class ClassChecks {
    constructor(
        public nPlus: number,
        public totalSecondsStudied: number,
        public totalStudiedString: string,
        public teamChecks: TeamChecks[],
        public ranks: Ranks[],
        public roundType: string,
        public roundName: string,
        public message?: string
    ) {}
}

export const getClassChecks = async (onlineUserId: string) => {
    try {
        const response = await instance.get<ClassChecks>(
            `checks/class/${onlineUserId}`
        );

        return {
            ...response.data,
            totalStudiedString: secondsToString(
                response.data.totalSecondsStudied,
                "ko"
            ),
        };
    } catch (e) {
        console.log("반별 체크인 정보 조회 에러", e);
        return e.response.data;
    }
};

export const postChecks = async (userId, type) => {
    try {
        const response = await instance.post(`checks`, {
            userId: userId,
            type: type,
        });
        return response.data;
    } catch (e) {
        console.log("체크인 start / stop 에러", e);
        return e.response.data;
    }
};

export const getUserCheckInInfo = async (onlineUserId) => {
    try {
        const response = await instance.get<UserCheckIn>(
            `checks/user/${onlineUserId}`
        );
        return {
            ...response.data,
            totalSecondsStudiedString: secondsToString(
                response.data.totalSecondsStudied,
                "ko"
            ),
        };
    } catch (e) {
        console.log("유저 체크인 정보 조회 에러:", e);
        return e.response.data;
    }
};
