import { atom } from "jotai";
import { secondsToString } from "../businessLogics/utils/_helper";
import { ClassChecks, UserCheckIn } from "../queries/api/checks";

interface ClickedMemberType {
  name: string;
  firstCheckIn: null | string;
}

export const isChecksMainPageAtom = atom(true);
export const classChecksInfoAtom = atom<ClassChecks>(
  new ClassChecks(0, 0, "", [], [], "", "")
);
export const selectedWeekNumAtom = atom<string>("");

export const checksRankingAtom = atom((get) => {
  const classChecksInfo = get(classChecksInfoAtom);
  const result = classChecksInfo.ranks.sort(
    (a, b) => b.weekOrder - a.weekOrder
  );

  return result;
});
export const stringWeeklyTimeAtom = atom((get) => {
  const ranksInfo = get(checksRankingAtom);

  const week = get(selectedWeekNumAtom);
  const selectedRankInfo = ranksInfo.filter(
    (rankInfo) => rankInfo.weekOrder === parseInt(week)
  );
  const result = selectedRankInfo[0].rankedUsers.map((rankInfo) => {
    return {
      name: rankInfo.name,
      totalTime: secondsToString(rankInfo.totalTime),
      1: secondsToString(rankInfo.secondsPerDay[1]),
      2: secondsToString(rankInfo.secondsPerDay[2]),
      3: secondsToString(rankInfo.secondsPerDay[3]),
      4: secondsToString(rankInfo.secondsPerDay[4]),
      5: secondsToString(rankInfo.secondsPerDay[5]),
      6: secondsToString(rankInfo.secondsPerDay[6]),
      0: secondsToString(rankInfo.secondsPerDay[0]),
    };
  });
  return result;
});

export const teamsChecksInfoAtom = atom((get) => {
  const classChecksInfo = get(classChecksInfoAtom);
  return classChecksInfo.teamChecks;
});

export const checkInInfoModalAtom = atom(false);
export const clickedMemberAtom = atom<ClickedMemberType>({
  name: "",
  firstCheckIn: null,
});

export const checkInStartStopModalAtom = atom(false);
export const checkInTypeAtom = atom<string>("");
export const checkInUserInfoAtom = atom<UserCheckIn>({} as UserCheckIn);
export const formattedcheckUserInfoAtom = atom((get) => {
  const userCheckInfo = get(checkInUserInfoAtom);
  const formattedUserChecksInfo = {
    ...userCheckInfo,
    firstCheckinTime:
      userCheckInfo.logs &&
      userCheckInfo.logs[userCheckInfo.logs.length - 1]?.type === "in"
        ? userCheckInfo.logs[userCheckInfo.logs.length - 1].time
            .split("T")[1]
            .slice(0, -5)
        : "-",
  };
  return formattedUserChecksInfo;
});
export const checkInTimerAtom = atom<number>(0);
export const checkInTimerStartAtom = atom<boolean>(false);
