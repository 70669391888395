export const NicepayPcScript = () => {
  return (
    <script
      async
      id="nicepayPcScript"
      src="https://web.nicepay.co.kr/v3/webstd/js/nicepay-3.0.js"
      type="text/javascript"
    />
  );
};
