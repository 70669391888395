import React from "react";
import Script from "next/script";

export const GtagInstall = () => {
  return (
    <React.Fragment>
      <Script
        id="import-gtag"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GOOGLE_ANALYTICS_TRACKING_ID}`}
        strategy="afterInteractive"
      />
      <Script
        id="run-gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  
                  gtag('config', '${process.env.GOOGLE_ANALYTICS_TRACKING_ID}', {'optimize_id': '${process.env.GOOGLE_OPTIMIZE_ID}', page_path: window.location.pathname,});
                  gtag('config', '${process.env.GOOGLE_ADS_TRACKING_ID}');
                  `,
        }}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-K9JZ4LT');
        `,
        }}
      />
    </React.Fragment>
  );
};
