import axios, { AxiosInstance } from "axios";

// const baseURL = process.env.NEXT_PUBLIC_SERVER;
// const baseOnlineURL = process.env.NEXT_PUBLIC_ONLINE_API_URL;

// const setInterceptors = (instance) => {
//     instance.interceptors.request.use(
//         function (config) {
//             const accessToken = cookie.get("accessToken");
//             config.headers.Authorization = `Bearer ${accessToken}`;
//             return config;
//         },
//         function (error) {
//             return Promise.reject(error);
//         }
//     );

//     // Add a response interceptor
//     instance.interceptors.response.use(
//         function (response) {
//             return response;
//         },
//         function (error) {
//                 const {
//                     config: { url },
//                     status,
//                 } = error.response;

//                 if (status === 401) {
//                     if (accessToken === undefined) {
//                         window.location.href = `/login`;
//                     }
//                 }
//             }
//             return Promise.reject(error);
//         }
//     );

//     return instance;
// };

const createInstance = () => {
    const instance = axios.create({
        baseURL: process.env.NEXT_PUBLIC_SERVER,
    });

    return instance;
};

const createOnlineInstance = () => {
    const instance = axios.create({
        baseURL: process.env.NEXT_PUBLIC_ONLINE_API_URL,
    });

    // return setInterceptors(instance);
    return instance;
};

// const formDataType = {
//     headers: {
//         "Content-Type": "multipart/form-data",
//     },
// };

export const instance: AxiosInstance = createInstance();
export const onlineInstance: AxiosInstance = createOnlineInstance();

// export { formDataType };
