import { useEffect, useState } from "react";
// import { parseText } from "../_common/auth";

export const setCookie = (name, value, days) => {
  let expires = "";
  const domain = document.location.origin.includes(".spartacodingclub.kr")
    ? ".spartacodingclub.kr"
    : document.location.hostname;
  const sameSite = document.location.origin.includes(".spartacodingclub.kr")
    ? "; SameSite=None; Secure"
    : "";
  let date = new Date();
  date.setTime(
    days ? date.getTime() + days * 24 * 60 * 60 * 1000 : date.getTime()
  );
  expires = `; domain=${domain}; expires=${date.toUTCString()}${sameSite}`;
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const useCookie = (name: string) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (window !== undefined) {
      setValue(getCookie(name));
    }
  }, []);
  return value;
};
