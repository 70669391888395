import { useAtom } from "jotai";
import { sendLog } from "../../../lib/logging";
import { useCheckEmailQuery, useFetchLoginQuery } from "../../queries/login";
import {
  emailCheckedAtom,
  handleEmailAtom,
  isSignInFailAtom,
  loginFailMessageAtom,
  passwordAtom,
  isExistAtom,
  notRegisteredEmailMessageAtom,
  readOnlyEmailAtom,
} from "../../states/login/login";
import { onlineUserIdAtom } from "../../states/user";
import { VERSION } from "../application";
import { setCookie } from "../util-browser/cookie.util";

export const useOnChangeEmail = () => {
  const [, setEmailChecked] = useAtom(emailCheckedAtom);
  const [, setEmail] = useAtom(handleEmailAtom);
  const [, setWarn] = useAtom(notRegisteredEmailMessageAtom);
  return (e) => {
    const targetValue = e.target.value;
    if (targetValue === "") {
      return setEmailChecked(false), setWarn("");
    }
    setEmail(targetValue);
  };
};

export const useOnChangePassword = () => {
  const [, setPassword] = useAtom(passwordAtom);
  return (e) => {
    const targetValue = e.target.value;
    setPassword(targetValue);
  };
};

export const useCheckRegisteredEmail = () => {
  const [email] = useAtom(readOnlyEmailAtom);
  const [emailChecked, setEmailChecked] = useAtom(emailCheckedAtom);
  const [, setIsExist] = useAtom(isExistAtom);
  const [, setMessage] = useAtom(notRegisteredEmailMessageAtom);

  const checkEmailQuery = useCheckEmailQuery();

  return async (e) => {
    if (e.key != "Enter" && e.type !== "click") {
      return false;
    }

    if (emailChecked || email === "") return;

    try {
      const resp = await checkEmailQuery();
      const {
        data: { is_exist },
      } = resp;
      setEmailChecked(true);
      setIsExist(is_exist);
      setMessage("해당 e-mail로 가입된 정보가 없습니다.");
    } catch (e) {
      console.log("이메일 등록 여부 체크 에러: ", e);

      return false;
    }
  };
};

export const useOnSubmitHandler = () => {
  const [, setIsSignInFail] = useAtom(isSignInFailAtom);
  const [, setOnlineUserId] = useAtom(onlineUserIdAtom);
  const [, setLoginFailMessage] = useAtom(loginFailMessageAtom);

  const fetchLoginQuery = useFetchLoginQuery();
  // const findUserByOnlineUserId = useFindUserByOnlineUserId();
  // const fetchCreateUser = useFetchCreateUser();

  return async (e) => {
    if (e.key != "Enter" && e.type !== "click") {
      return false;
    }

    // 온라인 서버를 이용한 로그인
    let onlineUser;
    try {
      const resp = await fetchLoginQuery();
      onlineUser = resp.data.user;
      setOnlineUserId(onlineUser._id);
      sendLog("hh_login_completed", { method: "email" });
    } catch (e) {
      setIsSignInFail(true);
      setLoginFailMessage(e.response.data.message);

      return false;
    }

    setCookie("userinfo", `_id=${onlineUser._id}`, 3);
    setCookie("token", `${onlineUser.token}`, 3);
  };
};

export const useLogout = () => {
  return () => {
    setCookie("userinfo", null, null);
    setCookie("token", null, null);
    window.location.href = "/";
  };
};

export const getParam = (name, url = null) => {
  if (!url) url = location.href;
  const queryName = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regexS = "[\\?&]" + queryName + "=([^&#]*)";
  const regex = new RegExp(regexS);
  const results = regex.exec(url);
  return results ? results[1] : null;
};

export const isPageForGraduates = (pathname) => {
  return pathname.includes("graduate");
};

export const isPageForOngoingUsers = (pathname) => {
  return pathname.includes("evaluation") || pathname.includes("homework");
};

export const authNotRequiredPage = (pathname) => {
  return (
    pathname.includes("exhibitions") ||
    pathname.includes("login") ||
    pathname.includes("orientation") ||
    pathname.includes("graduate")
  );
};

export const goToSignup = () => {
  let next = getParam("next");
  const fullPathToNext = `${process.env.NEXT_PUBLIC_PAGE_URL}/${VERSION}/${
    next ? `login?next=${encodeURIComponent(next)}` : `login`
  }`;
  window.location.replace(
    `${process.env.NEXT_PUBLIC_ONLINE_URL}/signup?next=${encodeURIComponent(
      fullPathToNext
    )}`
  );
};
