import { useAtom } from "jotai";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { getCookie } from "../../../businessLogics/util-browser/cookie.util";
import { useLogPageview } from "../../../businessLogics/_common/logging";
import { makeEventId } from "../../../businessLogics/utils/_helper";
import Head from "next/head";
import { classChecksInfoAtom } from "../../../states/checks";
import { loginUserInfoAtom } from "../../../states/application";
import { getJSONfromUserInfoCookie } from "../../../queries/login";
import { onlineUserIdAtom } from "../../../states/user";
import {
  GRADUATEPAGEURL,
  LOGINPAGEURL,
  PAYMENTCONFIRMATIONPAGEURL,
  redirectUrl,
  VBANKPAYMENTPAGEURL,
  VERSION,
} from "../../../businessLogics/application";
import {
  useRecruitingRounds,
  useUserApplication,
} from "../../../queries/application";
import {
  authNotRequiredPage,
  isPageForOngoingUsers,
} from "../../../businessLogics/login/login";

export default function Layout({ children }) {
  const router = useRouter();
  const { data: rounds } = useRecruitingRounds();
  useUserApplication();
  const [classChecksInfo] = useAtom(classChecksInfoAtom);
  const [, setOnlineUserId] = useAtom(onlineUserIdAtom);
  const [userInfo] = useAtom(loginUserInfoAtom);

  useLogPageview(makeEventId());

  //user가 funnel과 맞지 않은 page로 가려할 때 적절한 페이지로 redirect
  const redirectToUsersProperFunnel = (pathname) => {
    if (pathname.includes(`${GRADUATEPAGEURL}`)) return;
    if (pathname.includes(`${PAYMENTCONFIRMATIONPAGEURL}`)) {
      const { orderNo } = router.query;
      rounds && router.replace(redirectUrl(userInfo, orderNo, rounds[0].id));
    } else if (
      pathname.includes(`${VBANKPAYMENTPAGEURL}`) ||
      isPageForOngoingUsers(router.pathname)
    ) {
      return;
    } else {
      rounds &&
        router.replace(redirectUrl(userInfo, router.pathname, rounds[0].id));
    }
  };

  useEffect(() => {
    const onlineUserId = getCookie("userinfo");
    const path = router.pathname.replace(`/${VERSION}/`, "");
    const next =
      path === "login" ? `${LOGINPAGEURL}` : `${LOGINPAGEURL}?next=${path}`;

    //수료생 페이지에서 로그인 했을 경우, onlineUserIdAtom 필요함
    if (onlineUserId && router.pathname.includes("graduate")) {
      const { _id } = getJSONfromUserInfoCookie();
      setOnlineUserId(_id);
    }

    if (authNotRequiredPage(router.pathname)) return;

    if (router.pathname === "/") {
      const { _id } = getJSONfromUserInfoCookie();
      setOnlineUserId(_id);
      return;
    }

    if (!onlineUserId) {
      alert("로그인이 필요합니다!");
      router.replace(next);
      return;
    } else {
      const { _id } = getJSONfromUserInfoCookie();
      setOnlineUserId(_id);
      userInfo && redirectToUsersProperFunnel(router.pathname);
    }
  }, [userInfo]);
  console.log(router.pathname);
  return (
    <>
      <Head>
        <meta name="author" content="스파르타코딩클럽" />
        <meta
          name="description"
          content="코딩 부트캠프 항해99에 합류하세요. 99일 동안 개발 공부에만 몰입하면 IT 기업에서 환영 받는 주니어 개발자가 될 수 있습니다. 개발자로 취업한 후기들이 증명합니다."
        />
        <meta
          name="keywords"
          content="코딩 부트캠프, 주니어 개발자, 웹개발, 코딩공부, 프로그래밍, 파이썬, 코딩학원, 코딩 스터디, 부트캠프, 개발자, 코딩, 개발자 취업, AWS, 웹서버, 서버, 클라이언트, 자바스크립트, 리액트, React, Spring, 스프링, 앱개발, React Native, 프론트엔드, 백엔드, 풀스택"
        />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <meta
          name="naver-site-verification"
          content="1136f21cf1c34f7ea97ea47922288c23ec478634"
        />
        <meta name="yeti" content="index, follow" />
        <meta
          name="viewport"
          content="user-scalable=no,width=device-width,initial-scale=1,shrink-to-fit=no"
        />
        <meta
          httpEquiv="Content-Security-Policy"
          content="upgrade-insecure-requests"
        />

        <meta
          property="og:url"
          content={
            router.pathname.includes("graduate")
              ? `${process.env.NEXT_PUBLIC_PAGE_URL}/v2/graduate`
              : `${process.env.NEXT_PUBLIC_PAGE_URL}`
          }
        />
        <meta
          property="og:title"
          content={
            router.pathname.includes("graduate")
              ? "항해99 수료 축하 페이지"
              : "온라인 코딩부트캠프 항해99, 현업과 같은 실전프로젝트"
          }
        />
        <meta
          property="og:image"
          content={
            router.pathname.includes("graduate")
              ? `${process.env.NEXT_PUBLIC_PAGE_URL}/images/graduate/graduate_og_image.png`
              : ""
          }
        />
        <meta
          property="og:description"
          content={
            router.pathname.includes("graduate")
              ? "지난 99일 동안 보낸 여정을 살펴보세요!"
              : "99일 동안 동료들과 함께 개발 공부에만 몰입하세요. IT 기업에서 환영 받는 주니어 개발자로 성장할 수 있습니다."
          }
        />
        <meta property="og:site_name" content="항해99" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="ko_KR" />
        <link
          rel="shortcut icon"
          href={
            classChecksInfo.roundType === "INNOVATION"
              ? "/static/images/favicon/innovation_camp_favicon.png"
              : "/static/images/favicon/hanghae_favicon.ico"
          }
        />
      </Head>
      <>{children}</>
    </>
  );
}
