import { atom } from "jotai";

export const emailAtom = atom<string>("");
export const handleEmailAtom = atom(null, (get, set, update: string) => {
  set(emailAtom, update);
});
export const readOnlyEmailAtom = atom((get) => get(emailAtom));

export const passwordAtom = atom<string>("");

export const emailCheckedAtom = atom<boolean>(false);
export const isExistAtom = atom<boolean>(false);
export const loginFailMessageAtom = atom("");
export const notRegisteredEmailMessageAtom = atom(
  null,
  (get, set, update: string) => {
    if (get(emailCheckedAtom) && !get(isExistAtom)) {
      set(loginFailMessageAtom, update);
    } else {
      set(loginFailMessageAtom, "");
    }
  }
);
export const isSignInFailAtom = atom<boolean>(false);
