import React from "react";
import Script from "next/script";

export const KakaoPageview = (props: { url: string }) => {
    const { url } = props;
    return (
        <React.Fragment>
            <Script
                async
                id="kakaoInstall"
                type="text/javascript"
                charSet="UTF-8"
                src="//t1.daumcdn.net/adfit/static/kp.js"
            />
            <Script
                async
                id="kakaoPageview"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `kakaoPixel('${process.env.KAKAO_PIXEL_ID}').pageView({url: '${url}'});`,
                }}
            ></Script>
        </React.Fragment>
    );
};

export const KakaoViewContent = (props: { tag: string }) => {
    const { tag } = props;
    return (
        <React.Fragment>
            <Script
                async
                id="kakaoInstall"
                type="text/javascript"
                charSet="UTF-8"
                src="//t1.daumcdn.net/adfit/static/kp.js"
            />
            <Script
                async
                id="kakaoViewContent"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `kakaoPixel('${process.env.KAKAO_PIXEL_ID}').viewContent({tag: '${tag}'});`,
                }}
            ></Script>
        </React.Fragment>
    );
};

export const KakaoCompleteRegistration = (props: { tag: string }) => {
    const { tag } = props;
    return (
        <React.Fragment>
            <Script
                async
                id="kakaoInstall"
                type="text/javascript"
                charSet="UTF-8"
                src="//t1.daumcdn.net/adfit/static/kp.js"
            />
            <Script
                async
                id="kakaoViewContent"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `kakaoPixel('${process.env.KAKAO_PIXEL_ID}').completeRegistration('${tag}');`,
                }}
            ></Script>
        </React.Fragment>
    );
};
