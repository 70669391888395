import { atom } from "jotai";
import {
  FirstApplicationDto,
  UserApplicationDto,
} from "../queries/api/application/application.dto";

export const userPhoneNumberAtom = atom<string>("");
export const userApplicationAtom = atom<UserApplicationDto>(
  {} as UserApplicationDto
);
export const loginUserInfoAtom = atom((get) => {
  const userApplicationInfo = get(userApplicationAtom);
  const { ongoing, user, round, funnel, orderNo } = userApplicationInfo;
  const userInfo = {
    onlineUserId: user && user.onlineUserId,
    ongoing,
    round,
    funnel,
    orderNo,
  };
  return userInfo;
});
export const firstApplicationAtom = atom<FirstApplicationDto>(
  {} as FirstApplicationDto
);
export const userMotivationAtom = atom("");
