import { getUTCDate } from "../../src/businessLogics/homework";
import { sendFlounder } from "../../src/businessLogics/_common/logging/flounder";
import { trackMixpanel } from "./mixpanel";

export const sendLog = async (action, data) => {
  trackMixpanel(action, data);
  sendFlounder(action, data);
};

export const sendPaymentInfoLog = async (action, data) => {
  const beginTime = getUTCDate(data.begin_time).toISOString();
  const endTime = getUTCDate(data.end_time).toISOString();
  trackMixpanel(action, { ...data, begin_time: beginTime, end_time: endTime });
  sendFlounder(action, data);
};
