import { useEffect, useState } from "react";

export const useCountdown = (startDate, endDate) => {
  const now = new Date();
  const start = getUTCDate(startDate);
  const end = getUTCDate(endDate);
  const countDownDate = endDate ? end.getTime() : 0;
  const initialCountDown = endDate ? countDownDate - now.getTime() : 0;

  const [countDown, setCountDown] = useState(initialCountDown);

  useEffect(() => {
    if (!startDate && !endDate) return;
    if (now < start || now > end) return;
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return now < start || now > end ? null : getReturnValues(countDown);
};

export const getUTCDate = (strDate: string) => {
  const d = new Date(strDate);
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth();
  const date = d.getUTCDate();
  const hours = d.getUTCHours();
  const minutes = d.getUTCMinutes();
  const seconds = d.getUTCSeconds();

  return new Date(year, month, date, hours, minutes, seconds);
};

const getReturnValues = (countDown) => {
  const hours = Math.floor(countDown / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return getStringTime([hours, minutes, seconds]);
};

const getStringTime = (hms) => {
  const strTime = hms.map((number) => {
    return number < 10 ? `0${number}` : `${number}`;
  });

  return strTime;
};

export const checkEmptyInputField = (homework, file, chapter) => {
  const urlThumb = file ? file : homework.urlThumb !== "";
  const commonInputFieldCheck =
    homework.urlService !== "" &&
    homework.descService !== "" &&
    homework.nameService !== "" &&
    homework.urlVideo !== "" &&
    urlThumb;
  if (chapter === "웹개발 미니 프로젝트") {
    return (
      commonInputFieldCheck &&
      homework.urlGithub !== "" &&
      homework.q1 !== "" &&
      homework.q2 !== ""
    );
  } else {
    return (
      commonInputFieldCheck &&
      homework.urlGithubFront !== "" &&
      homework.urlGithubBack !== "" &&
      homework.stackFront !== "" &&
      homework.stackBack !== ""
    );
  }
};

export const getSubmittedHomework = (homework) => {
  const {
    homeworkId,
    teamId,
    urlService,
    urlVideo,
    urlThumb,
    nameService,
    descService,
    urlGithubFront,
    urlGithubBack,
    urlGithub,
    stackFront,
    stackBack,
    q1,
    q2,
  } = homework;
  return {
    homeworkId,
    teamId,
    urlService,
    urlVideo,
    urlThumb,
    nameService,
    descService,
    urlGithubFront,
    urlGithubBack,
    urlGithub,
    stackFront,
    stackBack,
    q1,
    q2,
  };
};
