import { useEffect } from "react";
import { hotjar } from "react-hotjar";

export const useHotjar = () => {
    if (process.env.IS_PROD) {
        useEffect(() => {
            hotjar.initialize(2932732, 6);
        }, []);
    }
};
