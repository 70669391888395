import { getDeviceId } from "../../src/businessLogics/utils/_helper";
import { hashSha256 } from "../../src/businessLogics/_common/auth/hashing";

export const initFbq = () => {
    if (typeof window !== "undefined") {
        window.fbq("init", process.env.FACEBOOK_PIXEL_ID, {
            external_id: hashSha256(getDeviceId()),
        });
        window.fbq("track", "PageView");
    }
};
