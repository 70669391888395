import { makeEventId } from "../utils/_helper";
import { track } from "../../../lib/facebook";
import { sendLog } from "../../../lib/logging";
import { gtagReportConversion, marketingGtag } from "../../../lib/gtag";

export const VERSION = "v2";

// redirect page 별 퍼널
export const redirectToApplication1 = [
  "지원철회",
  "환불완료",
  "서류불합격",
  "면접불합격",
];
export const redirectToApplication2 = ["1차서류완료"];
export const redirectToApplicationDone = [
  "서류합격",
  "면접예정",
  "환불요청",
  "2차서류완료",
  "취업완료(선불)",
];
export const redirectToPaymentPage = [
  "면접합격",
  "취업완료(혼합)",
  "후불1차분납완료",
  "후불2차분납완료",
  "후불3차분납완료",
];
export const redirectToCompletePaymentPage = ["결제완료", "후불완납"];

// 퍼널별 redirect url
export const LOGINPAGEURL = `/${VERSION}/login`;
export const CHECKINPAGEURL = `/${VERSION}/checks`;
export const FIRSTAPPLICATIOINPAGEURL = `/${VERSION}/application/1`;
export const SECONDAPPLICATIOINPAGEURL = `/${VERSION}/application/2`;
export const DONEAPPLICATIOINPAGEURL = `/${VERSION}/application/done`;
export const PAYMENTPAGEURL = `/${VERSION}/payment`;
export const VBANKPAYMENTPAGEURL = `/${VERSION}/payment/vbank`;
export const PAYMENTCONFIRMATIONPAGEURL = `/${VERSION}/payment/confirmation`;
export const GRADUATEPAGEURL = `/${VERSION}/graduate`;

export const redirectUrl = (userInfo, next, roundId) => {
  const { onlineUserId, ongoing, round, funnel, orderNo } = userInfo;
  if (ongoing) {
    return `${CHECKINPAGEURL}`;
  } else if (onlineUserId && !round && !funnel) {
    return `${FIRSTAPPLICATIOINPAGEURL}/${roundId}`;
  } else if (funnel && redirectToApplication1.includes(funnel.title)) {
    return `${FIRSTAPPLICATIOINPAGEURL}/${roundId}`;
  } else if (funnel && redirectToApplication2.includes(funnel.title)) {
    return `${SECONDAPPLICATIOINPAGEURL}`;
  } else if (funnel && redirectToApplicationDone.includes(funnel.title)) {
    return `${DONEAPPLICATIOINPAGEURL}`;
  } else if (
    funnel &&
    !next.includes("vbank") &&
    redirectToPaymentPage.includes(funnel.title)
  ) {
    return `${PAYMENTPAGEURL}`;
  } else if (funnel && redirectToCompletePaymentPage.includes(funnel.title)) {
    return `${PAYMENTCONFIRMATIONPAGEURL}/${orderNo}`;
  } else {
    return next;
  }
};

export const goToApply = (router, roundId = null) => {
  let eventId = makeEventId();
  track(
    "AddToCart",
    {
      content_category: "항해",
      content_type: "product",
    },
    eventId
  );
  sendLog("hh_mainPage_click_application", {});
  marketingGtag("add_to_cart", {
    id: "hanghae99",
  });
  gtagReportConversion("8UjHCPCc_scDENiI190C");
  if (roundId)
    return router.push({
      pathname: `v2/login`,
      query: { next: "application/1", roundId },
    });
  return router.push("v2/login");
};

export const getMoDateDay = (data) => {
  let days = ["일", "월", "화", "수", "목", "금", "토"];
  let _data = data.split("T")[0];
  let month = _data.split("-")[1];
  let date = _data.split("-")[2];
  let day = new Date(data).getDay();
  if (day === 6 || day === 4) day = day - 1;
  return `${month}/${date}(${days[day]})`;
};

export const getFirstApplicationLogData = (data) => {
  const logData = {
    online_user_id: data.onlineUserId,
    name: data.name,
    email: data.email,
    phone: data.phone,
    age: data.age,
    degree: data.degree,
    reference: data.reference,
    reference_detail: data.referenceDetail,
    payment_way: data.paymentWay,
    round_id: data.roundId,
    mygoal: data.myGoal,
    experience: data.experience,
  };

  return logData;
};

export const getSecondApplicationLogData = (data) => {
  const logData = {
    temp: false,
    online_user_id: data.onlineUserId,
    round_id: data.roundId,
    applicant_id: data.applicantId,
    motivation: data.motivation,
    free_question: "",
  };

  return logData;
};
