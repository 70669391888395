import React from "react";
import Script from "next/script";

const InitNaverScript = () => (
    <React.Fragment>
        <Script
            id="naver-script"
            type="text/javascript"
            strategy="beforeInteractive"
            src={"//wcs.naver.net/wcslog.js"}
        />

        <script
            id="prevent-fraud-click"
            type="text/javascript"
            charSet="UTF-8"
            async
            src={"https://t1.daumcdn.net/adfit/static/kp.js"}
        />
    </React.Fragment>
);
export default InitNaverScript;
