import mixpanel from "mixpanel-browser";

export const initMixpanel = () => {
  if (window["mixpanel"]) return;
  mixpanel.init(process.env.MIXPANEL_TOKEN, { debug: true });
  window["mixpanel"] = mixpanel;
};

export const identifyMixpanel = (userId) => {
  initMixpanel();
  mixpanel.identify(userId);
};

export const trackMixpanel = (event_name: string, properties = {}) => {
  // !process.env.IS_PROD
  //   ? console.log(`event, %c${event_name}`, "color:green; font-size:20px")
  //   : "";
  // !process.env.IS_PROD ? console.log(`properties:`, properties) : "";
  initMixpanel();
  mixpanel.track(event_name, properties);
};
