import { getCookie, setCookie } from "../util-browser/cookie.util";
import { useAtom } from "jotai";
import {
  firstApplicationAtom,
  userApplicationAtom,
  userPhoneNumberAtom,
} from "../../states/application";
import { type } from "os";
import { useEffect, useState } from "react";

export const makeEventId = () => {
  return (
    (+new Date()).toString(36) + "-" + Math.random().toString(36).slice(2, 9)
  );
};

export const createDeviceId = () => {
  let result = [];
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 20; i++) {
    result.push(chars.charAt(Math.floor(Math.random() * chars.length)));
  }
  return result.join("");
};

export const getDeviceId = () => {
  if (!getCookie("device_id")) {
    setCookie("device_id", createDeviceId(), 60);
  }
  return getCookie("device_id");
};

type Time = number | string;

export const secondsToString = (seconds: number, type?: string) => {
  const hourToSeconds = 3600;
  let hours: Time = Math.floor(seconds / hourToSeconds);
  let minutes: Time = Math.floor((seconds - hours * hourToSeconds) / 60);

  // 함수로 리팩토링 => 성능올릴 수도
  if (hours < 10 && hours !== 0) {
    hours = "0" + hours;
  }
  if (minutes < 10 && minutes !== 0) {
    minutes = "0" + minutes;
  }

  return type === "ko" ? `${hours}시간 ${minutes}분` : `${hours}h ${minutes}m`;
};

export const youtubeUrlConverter = (originalUrl: string) => {
  let regExp =
    /(youtube(-nocookie)?\.com|youtu\.be)\/(watch\?v=|v\/|u\/|embed\/?)?([\w-]{11})(.*)?/i;
  if (originalUrl && originalUrl.length !== 0) {
    let match = regExp.exec(originalUrl);
    return "https://www.youtube" + ".com/embed/" + match[4];
  }
};

export const useFillHyphen = () => {
  const [firstApplication, setFirstApplication] = useAtom(firstApplicationAtom);
  return function (e) {
    let phone = e.target.value;
    phone = phone.split("-").join("");
    if (phone.length < 4) {
      phone = phone;
      setFirstApplication({ ...firstApplication, phone: phone });
    } else if (phone.length < 8) {
      phone = phone.substr(0, 3) + "-" + phone.substr(3, phone.length);
      setFirstApplication({ ...firstApplication, phone: phone });
    } else if (phone.length < 11) {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 3) +
        "-" +
        phone.substr(6, phone.length);
      setFirstApplication({ ...firstApplication, phone: phone });
    } else {
      phone =
        phone.substr(0, 3) +
        "-" +
        phone.substr(3, 4) +
        "-" +
        phone.substr(7, phone.length);
      setFirstApplication({ ...firstApplication, phone: phone });
    }
  };
};

export const getVBankExpiredDate = () => {
  const date = addDays(new Date(), 1);
  const year = date.getFullYear();
  const month = ("0" + (1 + date.getMonth())).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return year + month + day;
};

export const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export interface Platform {
  android?: boolean;
  bb?: boolean;
  blackberry?: boolean;
  ipad?: boolean;
  iphone?: boolean;
  ipod?: boolean;
  kindle?: boolean;
  playbook?: boolean;
  silk?: boolean;
  "windows phone"?: boolean;
  cros?: boolean;
  mac?: boolean;
  linux?: boolean;
  win?: boolean;
}

export const checkPlatform = (ua) => {
  ua = ua.toLowerCase();
  let platform: Platform = {};
  let matched = { platform: "" };
  let userPlatform = "pc";
  let platform_match =
    /(ipad)/.exec(ua) ||
    /(ipod)/.exec(ua) ||
    /(windows phone)/.exec(ua) ||
    /(iphone)/.exec(ua) ||
    /(kindle)/.exec(ua) ||
    /(silk)/.exec(ua) ||
    /(android)/.exec(ua) ||
    /(win)/.exec(ua) ||
    /(mac)/.exec(ua) ||
    /(linux)/.exec(ua) ||
    /(cros)/.exec(ua) ||
    /(playbook)/.exec(ua) ||
    /(bb)/.exec(ua) ||
    /(blackberry)/.exec(ua) ||
    [];

  matched.platform = platform_match[0] || "";

  if (matched.platform) {
    platform[matched.platform] = true;
  }

  if (
    platform.android ||
    platform.bb ||
    platform.blackberry ||
    platform.ipad ||
    platform.iphone ||
    platform.ipod ||
    platform.kindle ||
    platform.playbook ||
    platform.silk ||
    platform["windows phone"]
  ) {
    userPlatform = "mobile";
  }

  if (platform.cros || platform.mac || platform.linux || platform.win) {
    userPlatform = "pc";
  }

  return userPlatform;
};

export const useElementWidth = (ref) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref && ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  }, [ref]);

  return width;
};
