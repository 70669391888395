export const Pretendard = `
@font-face {
  font-family: 'Pretendard';
  font-weight: 100;
  font-style: normal;
  src: url('/fonts/Pretendard/Pretendard-Thin.subset.woff2') format("woff2");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 200;
  font-style: normal;
  src: url('/fonts/Pretendard/Pretendard-ExtraLight.subset.woff2') format("woff2");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/Pretendard/Pretendard-Light.subset.woff2') format("woff2");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Pretendard/Pretendard-Regular.subset.woff2') format("woff2");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 500;
  font-style: normal;
  src: url('/fonts/Pretendard/Pretendard-Medium.subset.woff2') format("woff2");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 600;
  font-style: normal;
  src: url('/fonts/Pretendard/Pretendard-SemiBold.subset.woff2') format("woff2");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/Pretendard/Pretendard-Bold.subset.woff2') format("woff2");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 800;
  font-style: normal;
  src: url('/fonts/Pretendard/Pretendard-ExtraBold.subset.woff2') format("woff2");
}

@font-face {
  font-family: 'Pretendard';
  font-weight: 900;
  font-style: normal;
  src: url('/fonts/Pretendard/Pretendard-Black.subset.woff2') format("woff2");
}
`;
export const AlarmClock = `
@font-face {
    font-family: 'AlarmClock';
    font-weight: 400;
    font-style: normal;
    src: url('/fonts/AlarmClock/alarm_clock.ttf') format("ttf");
    font-display: swap;
}
`;
export const SpoqaHanSans = `
@font-face {
  font-family: 'SpoqaHanSans';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.woff2') format("woff2");
}

@font-face {
  font-family: 'SpoqaHanSans';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.woff2') format("woff2");
}

@font-face {
  font-family: 'SpoqaHanSans';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.woff2') format("woff2");
}

@font-face {
  font-family: 'SpoqaHanSans';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.woff2') format("woff2");
}

@font-face {
  font-family: 'SpoqaHanSans';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.woff2') format("woff2");
}
`;

export const NanumHandWritingDaughter = `
@font-face {
  font-family: 'NanumHandWritingDaughter';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/NanumHandWritingDaughter/NanumHandWritingDaughter.subset.woff2') format("woff2");
}
`;

export const AritaBuri = `
@font-face {
  font-family: 'Arita-buri';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/Arita-buri/Arita-buriHL.ttf');
}

@font-face {
  font-family: 'Arita-buri';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/Arita-buri/Arita-buriL.ttf');
}

@font-face {
  font-family: 'Arita-buri';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/Arita-buri/Arita-buriM.ttf');
}

@font-face {
  font-family: 'Arita-buri';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/Arita-buri/Arita-buriSB.ttf');
}
`;
export const GmarketSans = `
@font-face {
  font-family: 'GmarketSans';
  font-weight: 700;
  font-style: normal;
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/gmarket/GmarketSansBold.woff2') format('woff2');
  font-display: swap;
}`;

export const NanumDaSiSiJagHae = `
@font-face {
  font-family: 'Nanum-DaSiSiJagHae';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Nanum-DaSiSiJagHae/Nanum-DaSiSiJagHae.ttf');
}
`;
